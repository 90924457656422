import React from 'react';

import { useQuery } from '@app/QueryClient';
import { TApiOnboardingSettings } from '@app/Tour/types';
import { apiListOnboardingSettings } from '@app/Services/Tour';

import { generatedKeys } from '../queryKeysFactory';

const { onboardingSettings } = generatedKeys;

export function useListOnboardingSettings() {
	const q = useQuery({
		queryFn: apiListOnboardingSettings,
		staleTime: Infinity,
		queryKey: onboardingSettings.all?.({}) ?? [],
	});

	React.useDebugValue(q);
	return {
		...q,
		data: q?.data?.data as unknown as TApiOnboardingSettings | undefined,
		refetch: q.refetch,
	};
}
