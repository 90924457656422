import { CustomStepProps } from '@zeal/web-ui';

import { homeSteps } from '../OnboardingSteps/HomeOnboardingSteps';
import { overviewSteps } from '../OnboardingSteps/OverviewOnboardingSteps';
import { teamAdminsSteps } from '../OnboardingSteps/TeamAdminsSteps';
import { teamMembersSteps } from '../OnboardingSteps/TeamMembersSteps';
import { settingsSteps } from '../OnboardingSteps/SettingsOnboardingSteps';
import { branchesSteps } from '../OnboardingSteps/BranchesOnboardingSteps';

export const stepsMapping: Record<
	string,
	(t: (key: string) => string) => CustomStepProps[]
> = {
	home: homeSteps,
	overview: overviewSteps,
	'team-admins': teamAdminsSteps,
	'team-members': teamMembersSteps,
	branches: branchesSteps,
	settings: settingsSteps,
};

export const ApiStepsMapping: Record<string, string> = {
	home: 'home',
	overview: 'overview',
	'team-admins': 'administrators',
	'team-members': 'team_members',
	branches: 'branches',
	settings: 'settings',
};
